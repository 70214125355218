import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Layout from "../components/Layout"

const heroText =
  "REDΙ has a wide international network in both practice and academia that can ensure the successful delivery of resource-demanding projects."

export default function Tools() {
  return (
    <Layout
      bodyClass="tools"
      heroTitle="REDI Tools"
      heroText={heroText}
      bcText="REDI TOOLS"
    >
      <section className="tools-heading">
        <Container>
          <Row className="services-dev">
            <Col sm={12} md={6}>
              <Link className="icon-box" to="/tools_for_infrastructure">
                <img src="../images/icon-1.svg" alt="" />
                <p>
                  Tools for
                  <br />
                  Infrastructure
                </p>
              </Link>
            </Col>
            <Col sm={12} md={6}>
             <Link className="icon-box" to="/pre_earthquake_assessment">
                <img src="../images/icon-6.svg" alt="" />
                <p>
                Pre-Earthquake Damage Assessment
                  <br />
                  Using Artificial Intelligence Algorithms
                </p>
              </Link>
            </Col>
            <Col sm={12} md={6}>
             <a className="icon-box" target="_blank" href="https://rail-eq.rediengineering.gr">
                <img src="../images/icon-7.svg" alt="" />
                <p>
                Seismic Reliability of Railway Bridges under high-speed trains using a novel formulation for constrained dynamical systems
                </p>
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}
